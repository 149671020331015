import { Nav, Navbar } from "react-bootstrap";
import { ReactComponent as Logo } from "./logo.svg"

export function NavigationBar() {
    return (
        <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand href="/">
                <div id="nav-logo-title">
                    <Logo
                        fill="#E50914"
                        width="60"
                        height="30"
                        className="d-inline-block" />
                    <p id="top-title"> Netflix Helper </p>
                </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Nav.Link href="/Genres">Genres</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link href="https://www.netflix.com/login/">Netflix Login</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link href="/Instructions">Instructions</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link href="/PrivacyPolicy">Privacy Policy</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    );
}
