import { Container } from "react-bootstrap";

export function Instructions() {
  return (
    <Container>
      <p className="instructions-h1">Instructions</p>
      <div id="instructions-text" className="instructions-text">
        <ol id="instructions-list" className="instructions-list">
        <li>Login on Netflix. (login link provided in menu)</li>
        <li>Tap the cast icon in Netflix and select the device you would like to cast to (this works even if you have a smart tv and don't cast with your mobile device to third party hardware like a Roku or Chromecast).</li>
        <li>Go back to the Netflix Helper App</li>
        <li>Select the genre you would like to browse.</li>
        <li>The app will immediately switch to Netflix on your device.</li>
        <li>Select what you would like to watch and it will now play on your selected device. </li>
        <li>Enjoy.</li>
        </ol>
      </div>
      <h3>app version 1.1</h3>
    </Container>
  );
}