export { AllGenreData };

let AllGenreData = {
    Absurd: 108598,
    Action: 108532,
    Adult_Animation: 11881,
    Adventures: 7442,
    African_Movies: 3761,
    Alien_Sci_Fi: 3327,
    Amnesia: 103872,
    Animals: 100471,
    Animal_Tales: 5507,
    Anime: 7424,
    Anime_Movies: 3063,
    Anime_Series: 6721,
    Art_House_Movies: 29764,
    Asian_Action_Movies: 77232,
    Assassination: 103877,
    Australian_Movies: 5230,
    Auto_Racing: 100337,
    Baseball_Movies: 12339,
    Basketball_Movies: 12762,
    BBC: 100400,
    Biographical_Docs: 3652,
    Biographical_Dramas: 3179,
    Blockbuster: 109168,
    Boxing_Movies: 12443,
    British_Movies: 10757,
    British_TV_Shows: 52117,
    Campy_Movies: 1252,
    Cartoons: 100129,
    Celebrities: 107138,
    Cerebral: 100033,
    Chases: 104730,
    Children_and_Family_Movies: 783,
    Chinese_Movies: 3960,
    Classic_Action_and_Adventure: 46576,
    Classic_Comedies: 31694,
    Classic_Dramas: 29809,
    Classic_Movies: 31574,
    Comedies: 6548,
    Comic_Book_and_Superhero: 10118,
    Conspiracies: 103898,
    Courtroom_Dramas: 2748,
    Creature_Features: 6895,
    Crime_Action_and_Adventure: 9584,
    Crime_Documentaries: 9875,
    Crime_Dramas: 6889,
    Crime_Thrillers: 10499,
    Crime_TV_Shows: 26146,
    Cult_Comedies: 9434,
    Cult_Movies: 7627,
    Cult_TV_Shows: 74652,
    Dark_Comedies: 869,
    Demons: 104778,
    Documentaries: 6839,
    Dramas: 5763,
    Dramas_based_on_Books: 4961,
    Dramas_based_on_real_life: 3653,
    Emotional: 100039,
    Ensemble: 105023,
    Epics: 52858,
    Experimental_Movies: 11079,
    Faith_and_Spirituality: 26835,
    Family_Features: 51056,
    Fantasy_Movies: 9744,
    Fashion: 104055,
    Feel_Good: 100058,
    Film_Noir: 7687,
    Food_and_Travel_TV: 72436,
    Football_Movies: 12803,
    French_Movies: 58807,
    Gangster_Movies: 31851,
    Gay_and_Lesbian_Dramas: 500,
    German_Movies: 58886,
    Ghosts: 103336,
    Going_Green: 104732,
    Golden_Globe_Winners: 108861,
    Goofy: 100043,
    Gory_Movies: 615,
    Gritty: 100044,
    Heartfelt: 100045,
    Heists: 100427,
    Hidden_Gems: 109000,
    High_Octane: 108851,
    Historical_Documentaries: 5349,
    History: 108257,
    Horror_Comedy: 89585,
    Horror_Movies: 8711,
    Independent_Movies: 7077,
    Indian_Movies: 10463,
    Inspiring: 100047,
    Italian_Movies: 8221,
    Japanese_Movies: 10398,
    Kids_Music: 52843,
    Kids_TV: 27346,
    Korean_Movies: 5685,
    Korean_TV_Shows: 67879,
    Late_Night_Comedies: 1402,
    Latin_American_Movies: 1613,
    Latin_Music: 10741,
    LGBTQ_Movies: 5977,
    Magic: 67652,
    Martial_Arts_Movies: 8985,
    Medical: 103315,
    Middle_Ages: 104104,
    Middle_Eastern_Movies: 5875,
    Military_Action_and_Adventure: 2125,
    Military_Documentaries: 4006,
    Military_Dramas: 11,
    Military_TV_Shows: 25804,
    Mind_Games: 108853,
    Mobster: 100426,
    Mockumentaries: 26,
    Monsters: 100166,
    Movies_based_on_childrens_books: 10056,
    Music_and_Concert_Documentaries: 90361,
    Music: 1701,
    Musicals: 13335,
    Mysteries: 9994,
    NBC: 107964,
    Nickelodeon: 67606,
    Nostalgic: 104784,
    Ominous: 108911,
    Oscar_Winning_Movies: 51063,
    Period_Pieces: 12123,
    Political_Comedies: 2700,
    Political_Documentaries: 7018,
    Political_Dramas: 6616,
    Political_Thrillers: 10504,
    Pop: 2145,
    Psychological_Thrillers: 5505,
    Quirky_Romance: 36103,
    Reality_TV: 9833,
    Romantic_Comedies: 5475,
    Romantic_Dramas: 1255,
    Romantic_Favorites: 502675,
    Romantic_Independent_Movies: 9916,
    Romantic_Movies: 8883,
    Satanic_Stories: 6998,
    Satires: 4922,
    Scandinavian_Movies: 9292,
    Sci_Fi: 67656,
    Science_and_Nature_Documentaries: 2595,
    Science_and_Nature_TV: 52780,
    Showbiz_Dramas: 5012,
    Sitcoms: 3903,
    Slapstick_Comedies: 10256,
    Slasher_and_Serial_Killer_Movies: 8646,
    Soaps: 100196,
    Soccer_Movies: 12549,
    Social_and_Cultural_Documentaries: 3675,
    Social_Issue_Dramas: 3947,
    Southeast_Asian_Movies: 9196,
    Spanish_Movies_and_TV: 100396,
    Sports_and_Fitness: 9327,
    Sports_Comedies: 5286,
    Sports_Documentaries: 180,
    Sports_Dramas: 7243,
    Sports_Movies: 4370,
    Spy_Action_and_Adventure: 10702,
    Spy_Thrillers: 9147,
    Stand_up_Comedy: 11559,
    Supernatural_Horror_Movies: 42023,
    Supernatural_Thrillers: 11140,
    Tearjerkers: 6384,
    Teen_Comedies: 3519,
    Teen_Dramas: 9299,
    Teen_Screams: 52147,
    Teen_TV_Shows: 60951,
    Thrillers: 8933,
    Travel_and_Adventure_Documentaries: 1159,
    TV_Action_and_Adventure: 10673,
    TV_Cartoons: 11177,
    TV_Comedies: 10375,
    TV_Documentaries: 10105,
    TV_Dramas: 11714,
    TV_Horror: 83059,
    TV_Mysteries: 4366,
    TV_Sci_Fi_and_Fantasy: 1372,
    TV_Shows: 83,
    Twists_and_Turns: 104728,
    Underdog: 104022,
    Understated: 100057,
    Violent: 100034,
    Visually_striking: 100076,
    Wacky: 100043,
    Westerns: 7700,
    Witty_Movies: 2943,
    Zombie_Horror_Movies: 75405,
}