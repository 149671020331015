import { Container } from 'react-bootstrap';
import { AllGenreData } from './AllGenresData';
import { randomUrl, urlBuilder, reloadpage, generateString } from './helpers';
import { useState, useEffect } from 'react';
import disableScroll from 'disable-scroll';
import { motion } from 'framer-motion';
import { ReactComponent as Img } from './MoLovegood.svg';

export function NetflixTable() {
	window.oncontextmenu = function (event) {
		event.preventDefault();
		event.stopPropagation();
		event.cancelBubble = true;
		return false;
	};

	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	function setInitialModalValue() {
		// fetch the current cache
		let current_browser_cache = window.localStorage.getItem('modal_state');
		// current_browser_cache = null if person has never visited or token expired, false if the person visited
		let initial_modal_value = false;

		if (current_browser_cache == null) {
			initial_modal_value = true;
		}
		return initial_modal_value;
	}

	const [showModal, setShowModal] = useState(setInitialModalValue());

	useEffect(() => {
		window.localStorage.setItem('modal_state', JSON.stringify(showModal));
	}, [showModal]);

	if (showModal) {
		disableScroll.on();
	} else {
		disableScroll.off();
	}

	function setToggleValue() {
		// fetch the current cache
		let current_toggle = window.localStorage.getItem('toggle_state');
		console.log('Obtained cached value: ' + current_toggle);

		// current_browser_cache = null if person has never visited or token expired, false if the person visited

		let toggle_value = 'false';

		if (current_toggle != null) {
			toggle_value = current_toggle.toString();
		}
		return toggle_value;
	}

	const [isOn, setIsOn] = useState(setToggleValue());
	function toggleSwitch() {
		var obtained_value = isOn !== 'true';
		setIsOn(obtained_value.toString());
	}

	useEffect(() => {
		// console.log("Toggle state: " + isOn);
		window.localStorage.setItem('toggle_state', isOn);
	}, [isOn]);

	const openInApp = () => {
		if (isOn) {
			return reloadpage();
		} else {
			return null;
		}
	};

	return (
		<>
			{showModal && (
				<div className='main-modal-container' id='main-modal'>
					<div className='main-modal-div'>
						<div id='modal-div'>
							<h1>important please read</h1>

							<h3 style={{ margin: '2rem 0 2rem 0', fontSize: '2.2rem' }}>
								Shout out to Brazil for having the most downloads of Netflix
								Helper!
							</h3>
							<p>
								This app requires you to log into Netflix externally in browser
								and/or in the Netflix app. This is done to ensure that the app
								is able to open genre links with your Netflix account. If you
								are not logged in, you will be prompted to log in. This is not
								done on Netflix Helper. The sign in process happens on Netflix
								itself. There is no way for Netflix Helper to access the info
								entered when you log in to Netflix. For any other questions or
								concerns, please see the Privacy Policy.
							</p>
							<button id='modal-button' onClick={() => setShowModal(false)}>
								I Understand
							</button>
						</div>
					</div>
				</div>
			)}

			<Container className='tableContainer' id='main-container'>
				<p id='page-title'>Genres A-Z</p>
				{/* <Img
					onClick={() =>
						window.open('https://mo-love-co.myshopify.com/', '_blank')
					}
					fill='#E50914'
					id='ad-button'
				/> */}
				<div className='toggle-container'>
					<p className='toggle-options'>open links in Netflix</p>
					<div className='switch' data-isOn={isOn} onClick={toggleSwitch}>
						<motion.div className='handle' layout transition={spring} />
					</div>
					<p className='toggle-options'>open links in app</p>
				</div>
				<button type='button' id='surprise-button'>
					<a
						href={urlBuilder(randomUrl(AllGenreData))}
						onClick={openInApp}
						id='surprise-button-text'
					>
						surprise me
					</a>
				</button>
				<table>
					<tbody>
						{Object.entries(AllGenreData).map((key) => (
							<tr id='table-row-div' key={generateString(characters)}>
								<td id='table-row-box' key={generateString(characters)}>
									<a
										className='table-text'
										href={urlBuilder(key[1])}
										onClick={openInApp}
									>
										{key[0].replaceAll('_', ' ')}
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Container>
		</>
	);
}

const spring = {
	type: 'spring',
	stiffness: 700,
	damping: 30,
};
