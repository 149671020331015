export function IntroScreen() {

  setTimeout(function () {
    window.location.assign('/genres')
  }, 2750);

  return (
    <><div id="container">
      <div className="netflixintro" data-letter="N">
        <div className="helper-1">
          <div className="effect-brush">
            <span className="fur-31" />
            <span className="fur-30" />
            <span className="fur-29" />
            <span className="fur-28" />
            <span className="fur-27" />
            <span className="fur-26" />
            <span className="fur-25" />
            <span className="fur-24" />
            <span className="fur-23" />
            <span className="fur-22" />
            <span className="fur-21" />
            <span className="fur-20" />
            <span className="fur-19" />
            <span className="fur-18" />
            <span className="fur-17" />
            <span className="fur-16" />
            <span className="fur-15" />
            <span className="fur-14" />
            <span className="fur-13" />
            <span className="fur-12" />
            <span className="fur-11" />
            <span className="fur-10" />
            <span className="fur-9" />
            <span className="fur-8" />
            <span className="fur-7" />
            <span className="fur-6" />
            <span className="fur-5" />
            <span className="fur-4" />
            <span className="fur-3" />
            <span className="fur-2" />
            <span className="fur-1" />
          </div>
          <div className="effect-lumieres">
            <span className="lamp-1" />
            <span className="lamp-2" />
            <span className="lamp-3" />
            <span className="lamp-4" />
            <span className="lamp-5" />
            <span className="lamp-6" />
            <span className="lamp-7" />
            <span className="lamp-8" />
            <span className="lamp-9" />
            <span className="lamp-10" />
            <span className="lamp-11" />
            <span className="lamp-12" />
            <span className="lamp-13" />
            <span className="lamp-14" />
            <span className="lamp-15" />
            <span className="lamp-16" />
            <span className="lamp-17" />
            <span className="lamp-18" />
            <span className="lamp-19" />
            <span className="lamp-20" />
            <span className="lamp-21" />
            <span className="lamp-22" />
            <span className="lamp-23" />
            <span className="lamp-24" />
            <span className="lamp-25" />
            <span className="lamp-26" />
            <span className="lamp-27" />
            <span className="lamp-28" />
          </div>
        </div>
        <div className="helper-2">
          <div className="effect-brush">
            <span className="fur-31" />
            <span className="fur-30" />
            <span className="fur-29" />
            <span className="fur-28" />
            <span className="fur-27" />
            <span className="fur-26" />
            <span className="fur-25" />
            <span className="fur-24" />
            <span className="fur-23" />
            <span className="fur-22" />
            <span className="fur-21" />
            <span className="fur-20" />
            <span className="fur-19" />
            <span className="fur-18" />
            <span className="fur-17" />
            <span className="fur-16" />
            <span className="fur-15" />
            <span className="fur-14" />
            <span className="fur-13" />
            <span className="fur-12" />
            <span className="fur-11" />
            <span className="fur-10" />
            <span className="fur-9" />
            <span className="fur-8" />
            <span className="fur-7" />
            <span className="fur-6" />
            <span className="fur-5" />
            <span className="fur-4" />
            <span className="fur-3" />
            <span className="fur-2" />
            <span className="fur-1" />
          </div>
        </div>
        <div className="helper-3">
          <div className="effect-brush">
            <span className="fur-31" />
            <span className="fur-30" />
            <span className="fur-29" />
            <span className="fur-28" />
            <span className="fur-27" />
            <span className="fur-26" />
            <span className="fur-25" />
            <span className="fur-24" />
            <span className="fur-23" />
            <span className="fur-22" />
            <span className="fur-21" />
            <span className="fur-20" />
            <span className="fur-19" />
            <span className="fur-18" />
            <span className="fur-17" />
            <span className="fur-16" />
            <span className="fur-15" />
            <span className="fur-14" />
            <span className="fur-13" />
            <span className="fur-12" />
            <span className="fur-11" />
            <span className="fur-10" />
            <span className="fur-9" />
            <span className="fur-8" />
            <span className="fur-7" />
            <span className="fur-6" />
            <span className="fur-5" />
            <span className="fur-4" />
            <span className="fur-3" />
            <span className="fur-2" />
            <span className="fur-1" />
          </div>
        </div>
        <div className="helper-4">
          <div className="effect-brush">
            <span className="fur-31" />
            <span className="fur-30" />
            <span className="fur-29" />
            <span className="fur-28" />
            <span className="fur-27" />
            <span className="fur-26" />
            <span className="fur-25" />
            <span className="fur-24" />
            <span className="fur-23" />
            <span className="fur-22" />
            <span className="fur-21" />
            <span className="fur-20" />
            <span className="fur-19" />
            <span className="fur-18" />
            <span className="fur-17" />
            <span className="fur-16" />
            <span className="fur-15" />
            <span className="fur-14" />
            <span className="fur-13" />
            <span className="fur-12" />
            <span className="fur-11" />
            <span className="fur-10" />
            <span className="fur-9" />
            <span className="fur-8" />
            <span className="fur-7" />
            <span className="fur-6" />
            <span className="fur-5" />
            <span className="fur-4" />
            <span className="fur-3" />
            <span className="fur-2" />
            <span className="fur-1" />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
;
