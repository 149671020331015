import { NavigationBar } from './components/NavigationBar';
import { NetflixTable } from './components/NetflixTable';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { Instructions } from './components/Instructions';
import { IntroScreen } from './components/introScreen';

function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<IntroScreen />} />
        <Route path="/public\.well-known\assetlinks.json" />
        <Route path="/Genres" element={<NetflixTable />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Instructions" element={<Instructions />} />
      </Routes>
    </Router >
  );
}

export default App;