
export function urlBuilder(suffix: number) {
    let absUrl = 'https://www.netflix.com/browse/genre/';
    let absoluteUrl = new URL(absUrl).href;
    return absoluteUrl + suffix;
}

export function randomUrl(obj: any) {
    let keys = Object.keys(obj);
    let random = keys[Math.floor(Math.random() * keys.length)];
    return obj[random];
}

export function reloadpage() {
    window.location.reload();
}

export function generateString(input: any) {
    let result = '';
    const charactersLength = input.length;
    for (let i = 0; i < charactersLength; i++) {
        result += input.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

